import React from "react";

const WebDevBlog = () => {
  return ( 
    <div>
    <h1>BLOG</h1>
    <p>This is a more elaborate web development app for page 1.</p>
    {/* Add more components and functionality as needed */}
  </div>
   );
}
 
export default WebDevBlog;